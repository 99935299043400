<template>
  <div class="container">
    <div v-for="(imge, index) in this.images" :key="index" class="card">
<div class="card  d-flex align-items-center justify-content-center" >
<a :href="imge.img" download="name.png">

  <img class="card-img-top w-50" :src="imge.img"   alt="Img" >

</a>
<button @click="downloadImg(imge.img)">download</button>


  <div class="card-body">
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
  </div>
</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {},
  computed: {
    images() {
      let images = this.$store.getters["courses/answersGallery"].gallery;
      return images;
    }
  },
  methods: {
    downloadImg(responseUrl) {
    const fileName = responseUrl.split('/').pop();
	var el = document.createElement("a");
	el.setAttribute("href", responseUrl);
	el.setAttribute("download", fileName);
	document.body.appendChild(el);
 	el.click();
	el.remove();
    },

      remoteURL() {
      //return this.BASE_URL + link //link is the image's path fetched from database
      return this.link;
    },
},
  mounted() {
    this.$store.dispatch("courses/answersGallery").then(res => {
      console.log(res);
    });
  }
};
</script>
